export default {
	init() {

		function RenderGoogleMap( container ) {

			var markerIconUrl = window.location.origin +'/wp-content/themes/ketiw/img/svg/marker_location.svg';
			var markerHref = container.getAttribute('data-url');


			var lati = container.getAttribute('data-lati');
			var long = container.getAttribute('data-long');
			var marker_title = 'Z.P.U.H. KETIW s.c.';

			var map;
			    
		    var mapOptions = {
		        zoom: 18,
		        center: new google.maps.LatLng(lati, long), // New York
		        mapTypeControl: false,
		        mapTypeId: 'satellite',
		        streetViewControl: false,
		        fullscreenControl : false,
		        // tilt: 0,
		    };
		    
		    var mapElement = container;

		    map = new google.maps.Map(mapElement, mapOptions);

		    var mainMarkerIcon = { 
		    	url: markerIconUrl,
		    	scaledSize: new google.maps.Size(45, 73), // scaled size
			    origin: new google.maps.Point(0,0), // origin
			    anchor: new google.maps.Point(22, 60) // anchor
		    };

		    var marker = new google.maps.Marker({
		        position: new google.maps.LatLng(lati, long),
		        title: marker_title,
		        map: map,
		        icon : mainMarkerIcon,
		    });
			
		    if( markerHref ) {

			    google.maps.event.addListener(marker, 'click', function() {
			    	
			    	window.open( markerHref , '_blank');

				});

			}

		}

		var container = document.querySelectorAll('section.googleMap');

		if( container.length > 0 ) {

			container.forEach( function( box, index ) {	

				RenderGoogleMap( box );

			});

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
