import $ from 'jquery/dist/jquery';

export default {
	init() {

		// -- mobileMenu -- //

			function mobileMenu() {

				var mobileMenu = document.querySelector('section.mobileMenu');
				var hamburger = document.querySelector('header .header__hamburger button');

				hamburger.addEventListener('click', function(e) {

					e.stopPropagation();

					if( mobileMenu.classList.contains('active') ) {

						mobileMenu.classList.remove('active');
						hamburger.classList.remove('active');

					} else {

						mobileMenu.classList.add('active');
						hamburger.classList.add('active');

					}

				});

				document.addEventListener('click',function() {

					if( mobileMenu.classList.contains('active') ) {

						mobileMenu.classList.remove('active');
						hamburger.classList.remove('active');

					} 

				});

				mobileMenu.querySelector('.mobileMenu__content').addEventListener('click',function(e){

					e.stopPropagation();

				});

			}

			mobileMenu();

		// -- END -- //
    	
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
