import Hammer from 'hammerjs';

export default {

	init(){

		var container = document.querySelector('.serviceSectionBasic');

		if( container ) {

			// -- swipeSwitch -- // 

				function swipeSwitch( container ) {

					// -- Globals -- //

						// -- buttons
							var switchContainer = container.querySelector('.serviceSectionBasic__switch');
							var switchContainerWidth = parseInt( getComputedStyle(switchContainer).width.replace('px','') );
							var carousel = switchContainer.querySelector('.switch__carousel');
							var buttons = carousel.querySelectorAll('.switch__single');
						// -- layout
							var content = container.querySelector('.serviceSectionBasic__content');
							var layouts = content.querySelectorAll('.content__single');

						var carouselStart = 0;
						var carouselEnd = 0;

						var buttonsWidth = 0;
						var switchHeight = 0;
						var switchHeightTemp = 0;
						var windowWidth = 0;

						var lastPosition = 0;

						var buttonsRange = [];
						var prevStart = 0;
						var prevEnd = 0;
						var prevCenter = 0;
						var currentButtonCenter = 0;
						var findFirst = false;

					// -- END -- //



					// -- functions -- // 

						function getLayoutHeight() {

							layouts.forEach( function( layout,index ) {

								if( layout.classList.contains('active') ) {

									content.style.height = layout.offsetHeight+'px';

								}

							});

							
						}

						function setCarouselTransform( x ) {

							if( switchContainer.classList.contains('items--start') ) {

								carousel.style.transform = 'translateX('+x+'px)';

							}

						}

						function getButtonsWidth() {

							buttonsWidth = 0;

							buttons.forEach( function( button, index ) {

								buttonsWidth += button.offsetWidth + parseFloat( getComputedStyle(button).marginRight.replace('px','') );

							});

							if( buttonsWidth > switchContainerWidth ) {

								switchContainer.classList.add('items--start');

							} else {

								switchContainer.classList.remove('items--start');
								setCarouselTransform( 0 );

							}

							carouselEnd = switchContainerWidth - buttonsWidth;

						}

						function setButtonCenter() {

							currentButtonCenter = ( windowWidth / 2 );

							buttons.forEach( function( button, index ) {

								if( button.classList.contains('active') ) {

									currentButtonCenter -= ( button.offsetWidth / 2 );
									lastPosition = currentButtonCenter;
									setCarouselTransform( lastPosition );

								} else {

									currentButtonCenter -= ( button.offsetWidth + parseFloat( getComputedStyle(button).marginRight.replace('px','') ) );

								}

							});							

						}

						function getButtonsRange() {

							prevStart = ( windowWidth / 2 );

							buttons.forEach( function( button,index ) {

								if( index > 0 ) {

									prevStart = prevEnd;

								} 

								prevEnd = prevStart - ( button.offsetWidth + parseFloat( getComputedStyle(button).marginRight.replace('px','') ) );
								prevCenter = prevStart - ( button.offsetWidth / 2 );

								buttonsRange[index] = {
									'start' : prevStart,
									'end' : prevEnd,
									'center' : prevCenter,
								}


							});

						}

						function setHeight() {

							switchHeight = 0;

							buttons.forEach( function( button, index ) {

								switchHeightTemp = 0;

								switchHeightTemp += button.querySelector('.single__thumbnail').offsetHeight + parseFloat( getComputedStyle(button.querySelector('.single__thumbnail') ).marginBottom );
								switchHeightTemp += button.querySelector('.single__title').offsetHeight + parseFloat( getComputedStyle(button.querySelector('.single__title') ).marginBottom );

								if( switchHeight < switchHeightTemp ) {

									switchHeight = switchHeightTemp;

								}

							});

							switchContainer.style.height = switchHeight+'px';

						}

						function getWindowWidth() {

							windowWidth = window.innerWidth - 2 * parseFloat( getComputedStyle(switchContainer).marginRight.replace('px','') ) ;

						}

					// -- END -- //



					// -- ClickAction -- //

						buttons.forEach( function( button, index ) {

							button.addEventListener('click', function() {

								carousel.classList.remove('transformAnimation');

								if( buttonsWidth > windowWidth && window.innerWidth > 600 ) {
									
									buttons.forEach( function( buttonC, indexC) {

										if( buttonC.classList.contains('active') && index != indexC ) {

											if( index > indexC ) {

												lastPosition = carouselEnd;
												setCarouselTransform( lastPosition );

											} else {

												lastPosition = 0;
												setCarouselTransform( lastPosition );

											}

										}

									});

								} else if( window.innerWidth <= 600 ) {

									lastPosition = buttonsRange[index].center
									setCarouselTransform( lastPosition );
									
								}

								carousel.classList.add('transformAnimation');

								buttons.forEach( function(button2,index2) {

									button2.classList.remove('active');

								});

								layouts.forEach( function( content, index3 ) {

									content.classList.remove('active');

								});

								button.classList.add('active');
								layouts[index].classList.add('active');

								content.style.height = layouts[index].offsetHeight+'px';

							});

						});

					// -- END -- //



					// -- Hammer -- //

						var mc = new Hammer( switchContainer );

						mc.on('panstart', function(){

							carousel.classList.remove('transformAnimation');

						});

						mc.on('panleft', function( e ) {

							setCarouselTransform( lastPosition+e.deltaX );

						});

						mc.on('panright', function( e ) {

							setCarouselTransform( lastPosition+e.deltaX );

						});

						mc.on('panend', function( e ) {

							carousel.classList.add('transformAnimation');

							lastPosition = lastPosition + e.deltaX;

							if( window.innerWidth > 600 ) { // desktop

								if( lastPosition > 0 ) {

									lastPosition = 0;
									

								} else if( lastPosition < carouselEnd ) {

									lastPosition = carouselEnd;

								} 

							} else { // mobile

								buttons.forEach( function( button,index ) {

									button.classList.remove('active');
									layouts[index].classList.remove('active');

								});

								if( lastPosition > buttonsRange[0].start ) {

									lastPosition = buttonsRange[0].center;
									buttons[0].click();

								} else if( lastPosition  < buttonsRange[buttons.length-1].end ) {

									lastPosition = buttonsRange[buttons.length-1].center;
									buttons[buttons.length-1].click();

								} else {

									findFirst = false;

									buttonsRange.forEach( function( row , index ) {

										if( row.start > lastPosition && lastPosition > row.end && !findFirst ) {

											findFirst = true;
											lastPosition = row.center;
 											buttons[index].click();

 										} 

									});
									
								}

							}

							setCarouselTransform( lastPosition );
							
						});

					// -- END -- //



					// -- init -- //

						function init() {

							switchContainerWidth = parseInt( getComputedStyle(switchContainer).width.replace('px','') );

							getButtonsWidth();
							setHeight();
							getWindowWidth();
							getButtonsRange();
							getLayoutHeight();

							if( window.innerWidth <= 600 ) {

								setButtonCenter();

							} else {

								switchContainer.classList.add('items--start');
								setCarouselTransform( 0 );
								switchContainer.classList.remove('items--start');

							}

						}

						init();

					// -- END -- //



					// -- Resize -- //

						window.addEventListener('resize', function() {

							init();

						});

					// -- END -- //

				}

				swipeSwitch( container );


			// -- END -- //

		}

	},
	finalize(){

	}
}