export default {

	init(){

		var container = document.querySelector('.header .languages');

		if( container ) {

			function languagesSwitch( container ) {

				var list = container.querySelector('.languages__list');
				var listItems = list.querySelectorAll('li');

				container.addEventListener('click', function(e){

					e.stopPropagation();

					if( !container.classList.contains('open') ) {

						container.classList.add('open');

					} else {

						container.classList.remove('open');

					}

				});

				document.addEventListener('click', function(){

					if( container.classList.contains('open') ) {

						container.classList.remove('open');

					}

				});

				

			}

			languagesSwitch( container );

			function setShadowHeight( container )  {

				var list = container.querySelector('.languages__list');
				var listItems = list.querySelectorAll('li');
				var shadowBox = container.querySelector('.languages--shadow');
				var tempHeight = 0;

				function calcHeight() {

					tempHeight = 0;

					listItems.forEach( function( item,index ) {

						tempHeight += item.offsetHeight;

					});

					list.style.height = tempHeight + 'px';

					shadowBox.style.height = (tempHeight+container.offsetHeight) + 'px';

				}

				window.addEventListener('resize', function() {

					calcHeight();					

				});

				calcHeight();

			}

			setShadowHeight( container );

		}

	},
	finalize() {

	}

}