import slick from 'slick-carousel';
import $ from 'jquery';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default{

	init(){

		var container = document.querySelector('.history');

		if( container ) {

			function historySlaider( container ) {

				var carousel = container.querySelector('.elastic--carousel .carousel__content');
				var images = carousel.querySelectorAll('.content__image');

				var canOpenPS = true;

				// -- slick -- //

					$(carousel).slick({
						infinite : true,
						slidesToShow : 4,
						arrows: true,
						autoplay: true,
  						autoplaySpeed: 2000,
						prevArrow : $(container.querySelector('.carousel__button--prev') ),
						nextArrow : $(container.querySelector('.carousel__button--next') ),
						responsive : [
							{
								breakpoint: 1024,
								settings: {
									slidesToShow: 3,
								}
							},
							{
								breakpoint: 680,
								settings: {
									slidesToShow: 2,
									arrows: false,
								}
							}
						]
					});

					$(carousel).on('beforeChange', function(event, slick, currentSlide) {

						console.log('beforeChange');
						canOpenPS = false;

					});

					$(carousel).on('afterChange', function(event, slick, currentSlide) {

						console.log('afterChange');
						canOpenPS = true;

					});


				// -- END -- //



				// -- PS -- // 

					// -- INIT -- //

						var pswp = document.querySelectorAll('.pswp')[0]; // photoSwipe mask
						var items; // photoSwipe images
						var options = Array(); // photoSwipe options
						var photoSwipe; // photoSwipe object

						function Init(index) {

							items = Array();

							options.index = parseInt(index, 10);
							options.zoomEl = true;
							options.shareEl = false;

							var item; 
							var image;
							var image_url;
							var image_dimention;
							var image_width;
							var image_height;

							images.forEach( function( image , index2 ) {

								image_url = image.getAttribute('data-url');
								image_dimention = image.getAttribute('data-size').split('x');
								image_width = image_dimention[0];
								image_height = image_dimention[1];

								item = {

									src: image_url,
				                    w: image_width,
				                    h: image_height

								};

								items.push( item );

							});

							photoSwipe = new PhotoSwipe( pswp, PhotoSwipeUI_Default, items, options);

						}

						Init();

					// -- END -- //



					// -- OPEN -- //

						images.forEach( function( image, index ) {

							image.addEventListener('click' , function() {

								console.log('click!!');
								if( canOpenPS ) {

									Init( index );
									photoSwipe.init();

								}

							});

						});

					// -- END -- ///


				// -- END -- //

			}

			historySlaider( container )
		
		}

	},
	finalize(){

	}

}