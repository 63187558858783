export default {
	init() {

		var container = document.querySelector('form.form');

		if( container ) {

			var form = document.querySelector('form.form');
			var formBox = form.querySelectorAll('.form__box.require');
			var submit = form.querySelector('.form__submit');
			var formBoxEmpty;
			var formBoxInput;
			var formCallback;


			var formSuccess = container.getAttribute('data-success');
			var formEmpty = container.getAttribute('data-empty');
			var formError = container.getAttribute('data-error');

			submit.addEventListener('click',function(e){

				e.preventDefault();

				formCallback = form.querySelectorAll('.form__callback');

				if( !submit.classList.contains('disable') ) {

					submit.classList.add('disable');

					if( formCallback.length != 0 ) {

						formCallback.forEach( function( el , index ){

							el.remove();

						});

					}
					
					formBox.forEach( function( box, index ){

						formBoxInput = null;

						if( box.classList.contains('form--input') ) {

							formBoxInput = box.querySelector('input');

							if( formBoxInput.value ) {

								formBoxInput.classList.remove('empty');

	 						} else {

	 							formBoxInput.classList.add('empty');

	 						}

						} else if( box.classList.contains('form--textarea') ) {

							formBoxInput = box.querySelector('textarea');

							if( formBoxInput.value ) {

								formBoxInput.classList.remove('empty');

							} else {

								formBoxInput.classList.add('empty');

							}

						} else if( box.classList.contains('form--label') ) {

							formBoxInput = box.querySelector('input');

							if( formBoxInput.checked ) {

								box.classList.remove('empty');

							} else {

								box.classList.add('empty');

							}

						}

					});

					formBoxEmpty = form.querySelectorAll('.empty');

					if( formBoxEmpty.length == 0 ) {

						var formData = ''+
							'action=' 		+ 'sendMail' + '&' +
							'firstName=' 	+ form.querySelector('input[name="firstName"]').value + '&' +
							'lastName='		+ form.querySelector('input[name="lastName"]').value + '&' +
							'email=' 		+ form.querySelector('input[name="email"]').value + '&' +
							'phone=' 		+ form.querySelector('input[name="phone"]').value + '&' +
							'message=' 		+ form.querySelector('textarea[name="message"]').value + '&' +
							'security='		+ form.querySelector('input#authorize').value
						;

						var ajaxUrl = document.querySelector('footer').getAttribute('data-endpoint');				
						var response;
						
						var xhr = new XMLHttpRequest();
						xhr.onreadystatechange = function() {
							
							if( xhr.readyState === 4 && xhr.status === 200 ) {

								response = JSON.parse(xhr.responseText);
								
								if( response.status == 'success') {

									submit.insertAdjacentHTML('beforebegin', '<p class="form__callback form__callback--success">'+formSuccess+'</p>');

								} else if( response.status == 'error' ) {

									submit.insertAdjacentHTML('beforebegin', '<p class="form__callback form__callback--error">'+formError+'</p>');

								}

							}

						}
						xhr.open( 'POST', ajaxUrl, true );
						xhr.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' );
						xhr.send( formData );

					} else {

						submit.classList.remove('disable');

						submit.insertAdjacentHTML('beforebegin','<p class="form__callback form__callback--error">'+formEmpty+'</p>');

					}

				}

			});

		}
    
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
