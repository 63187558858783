import jQuery from 'jquery';
import './style.scss';

// LazyLoad
import LazyLoad from './lazyLoad/lazyLoad';

// Layout
import Subpage from '../src/Layout/Subpage';

// Pages
import History from '../src/Pages/History/History';
import Gallery from '../src/Pages/Gallery/Gallery';

// Sections
import ServiceSectionBasic from '../src/Sections/ServiceSectionBasic/ServiceSectionBasic';
import ServiceSectionFull from '../src/Sections/ServiceSectionFull/ServiceSectionFull';
import ArticleImageFix from '../src/Sections/ArticleImageFix/ArticleImageFix';

// Components
import Cookie from '../src/Components/Cookie/Cookie';
import LanguagesList from '../src/Components/LanguagesList/LanguagesList';
import ContactForm from '../src/Components/ContactForm/ContactForm';
import GoogleMap from '../src/Components/GoogleMap/GoogleMap';

LazyLoad.init();

document.addEventListener("DOMContentLoaded", function() {

	// Layout
	Subpage.init();

	// Pages
	History.init();
	Gallery.init();

	// Sections
	ServiceSectionBasic.init();
	ServiceSectionFull.init();
	ArticleImageFix.init();

	// Components
	Cookie.init();
	LanguagesList.init();
	ContactForm.init();
	GoogleMap.init();

});
