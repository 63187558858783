export default {

	init() {

		var container = document.querySelector('section.articleImageFix');

		if( container ) {

			function checkIfMobile( container ) {

				if ("ontouchstart" in document.documentElement) { // mobile
			        
			        // console.log("your device is a touch screen device.");
			        container.classList.add('articleImageFix--mobile')

			    } else { // desktop

			        // console.log("your device is NOT a touch device");
			        container.classList.remove('articleImageFix--mobile');

			    }

			}

			checkIfMobile( container );

			window.addEventListener('resize', function() {

				checkIfMobile( container );

			});

			
		}

	}

}