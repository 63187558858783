import $ from 'jquery';
import slick from 'slick-carousel';
import scrollama from 'scrollama';

export default {

	init() {

		// alert(window.innerWidth);

		var container = document.querySelector('.serviceSectionFull');

		if( container ) {

			// -- scrollToService -- //

				function scrollToService( container ) {

					var switchContainer = container.querySelector('.serviceSectionFull__switch');
					var switchButton = switchContainer.querySelectorAll('.switch__single');
					var layouts = container.querySelectorAll('.serviceSectionFull__services .services__single');

					var layoutTop;

					function scrollToLayout( index ) {

						layoutTop = $(layouts[index]).offset().top - ( switchContainer.offsetHeight + 5 );

						if( window.innerWidth < 1025 ) {

							layoutTop -= document.querySelector('header').offsetHeight;

						}

						$('html ,body').animate({'scrollTop': layoutTop }, 'smooth');

					}

					switchButton.forEach( function( button, index ) {

						button.addEventListener('click', function(){

							scrollToLayout(index);

						});	

					});

				}

				scrollToService( container );

			// -- END -- //



			// -- slickGallery -- //

				function slickGallery ( container ) {

					var galleries = container.querySelectorAll('.gallery__slick');
					var random = 0;

					if( galleries.length > 0 ) {

						galleries.forEach( function( gallery, index ) {

							random = Math.floor(Math.random() * 2000) + 1700;

							$( gallery.querySelector('.slick__images') ).slick({
								infinite : true,
								slidesToShow : 1,
								arrows: false,
								fade: true,
  								cssEase: 'linear',
  								dots: true,
  								appendDots: gallery.querySelector('.slick__dots'),
  								autoplay: true,
  								autoplaySpeed: random ,
							});

						});

					}

				}

				slickGallery( container );

			// -- END -- //



			// -- scrollama -- //

				function serviceScrollama( container ) {

					var buttons = container.querySelectorAll('.switch__single');
					
					const scroller = scrollama();

					scroller.setup({
						step: container.querySelectorAll('.services__single'),
					})
					.onStepEnter( function(response){

						buttons.forEach( function( button, index ) {

							button.classList.remove('active');

						});

						buttons[response.index].classList.add('active');

					});
					window.addEventListener('resize', scroller.resize);

				}

				serviceScrollama( container );

			// -- END -- //

		}

	},
	finalize(){

	}

}